import React from "react";
import MyRouter from "routers/index";
import {Provider} from 'react-redux';
import {store} from './store/store';

function App() {
  return (
      <Provider store={store}>
          <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 min-h-screen">
              <MyRouter/>
          </div>
      </Provider>
  );
}

export default App;
