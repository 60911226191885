import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import loadable from "@loadable/component";

const CheckOutPage = loadable(() => import("../containers/CheckOutPage/CheckOutPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const PageHome = loadable(() => import('containers/PageHome/PageHome'), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const Page404 = loadable(() => import('containers/Page404/Page404')) as React.FC;
const PageContact = loadable(() => import('containers/PageContact/PageContact'), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const PageAbout = loadable(() => import("containers/PageAbout/PageAbout"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const SiteHeader = loadable(() => import('containers/SiteHeader')) as React.FC;
const Footer = loadable(() => import('shared/Footer/Footer')) as React.FC;
const ScrollToTop = loadable(() => import('./ScrollToTop')) as React.FC;
const VisaStartPage = loadable(() => import("../containers/VisaPage/VisaStartPage/VisaStartPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage = loadable(() => import("../containers/VisaPage/Page/VisaPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage1 = loadable(() => import("../containers/VisaPage/Page1/VisaPage1"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage2 = loadable(() => import("../containers/VisaPage/page2/VisaPage2"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage3 = loadable(() => import("../containers/VisaPage/Page3/VisaPage3"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage4 = loadable(() => import("../containers/VisaPage/Page4/VisaPage4"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage5 = loadable(() => import("../containers/VisaPage/Page5/VisaPage5"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage6 = loadable(() => import("../containers/VisaPage/Page6/VisaPage6"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage7 = loadable(() => import("../containers/VisaPage/Page7/VisaPage7"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaPage8 = loadable(() => import("../containers/VisaPage/Page8/VisaPage8"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaCheckPage = loadable(() => import("../components/VisaCheckPage/VisaCheckPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaCheckResultPage = loadable(() => import("../components/VisaCheckPage/VisaCheckResultPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const VisaComplete = loadable(() => import("../containers/VisaPage/VisaComplete/VisaComplete"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const ServicesPage = loadable(() => import("../containers/SevicesPage/ServicesPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const LoadingScreen = loadable(() => import('../components/LoadingScreen/LoadingScreen')) as React.FC;
const SnackBar = loadable(() => import('../components/SnackBar/SnackBar')) as React.FC;
const TourPage = loadable(() => import("containers/Tours/Tours"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const EditPhotoPage = loadable(() => import("../components/VisaCheckPage/EditPhotoPage/EditPhotoPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const EditSuccessPage = loadable(() => import("../components/VisaCheckPage/EditPhotoPage/EditSuccessPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const PagePayment = loadable(() => import("../containers/PagePayment/PagePayment"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const PagePaymentSuccess = loadable(() => import("../containers/PagePayment/PagePaymentSuccess"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const PageUploadReceipt = loadable(() => import("../containers/PagePayment/PageUploadReceipt/PageUploadReceipt"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const TermsPage = loadable(() => import("../components/TermsPage/TermsPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const ConfidentialityPage = loadable(() => import("../components/Confidentiality/ConfidentialityPage"), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const BlogPage = loadable(() => import('containers/BlogPage/BlogPage'), {
  fallback: <div className={'min-h-screen'}></div>,
}) as React.FC;
const BlogSingle = loadable(() => import('containers/BlogPage/BlogSingle')) as React.FC;

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/blog", component: BlogPage },
  { path: "/blog/:title", component: BlogSingle },
  { path: "/contact", component: PageContact },
  { path: "/terms", component: TermsPage },
  { path: "/confidentiality", component: ConfidentialityPage },
  { path: "/visastart", component: VisaStartPage },
  { path: "/visa", component: VisaPage },
  { path: "/visa1", component: VisaPage1 },
  { path: "/visa2", component: VisaPage2 },
  { path: "/visa3", component: VisaPage3 },
  { path: "/visa4", component: VisaPage4 },
  { path: "/visa5", component: VisaPage5 },
  { path: "/visa6", component: VisaPage6 },
  { path: "/visa7", component: VisaPage7 },
  { path: "/visa8", component: VisaPage8 },
  { path: "/visa-complete", component: VisaComplete },
  { path: "/visa-check", component: VisaCheckPage },
  { path: "/checkout", component: CheckOutPage },
  { path: "/check_result/:uid", component: VisaCheckResultPage },
  { path: "/edit_photo/:uid", component: EditPhotoPage },
  { path: "/edit_success", component: EditSuccessPage },
  { path: "/contact", component: PageContact },
  { path: "/services", component: ServicesPage },
  { path: "/about", component: PageAbout },
  { path: "/tour/:id", component: TourPage },
  // { path: "/transport/:id", component: TransportPage },
  // { path: "/hotel/:id", component: HotelPage },
  { path: "/payment/:uid", component: PagePayment },
  { path: "/payment_success", component: PagePaymentSuccess },
  { path: "/upload_receipt/:uid", component: PageUploadReceipt },
  //
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <SiteHeader />
      <LoadingScreen/>
      <SnackBar/>
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route path={'*'} element={<Page404/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
